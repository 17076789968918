<template>
  <li class="nav-item nav-search">
 
    <a
      href="javascript:void(0)"
      class="nav-link nav-link-search"
      @click="showSearchBar = true"
    >
      <feather-icon
        icon="SearchIcon"
        size="21"
      />
    </a>

    <!-- Input -->
    <div
      class="search-input"
      :class="{'open': showSearchBar}"
    >
      <div class="search-input-icon">
        <feather-icon icon="SearchIcon" />
      </div>
      <!-- <input type="text" placeholder="Explore Vuexy...." class="form-control-input"> -->
      <!-- @keyup.esc="escPressed" -->
      <!-- @keyup.enter="suggestionSelected" -->
      <b-form-input
        v-if="showSearchBar"
        v-model="searchQuery"
        placeholder="Recherche global"
        autofocus
        autocomplete="off"
        @keyup.up="increaseIndex(false)"
        @keyup.down="increaseIndex"
        @keyup.esc="showSearchBar = false; resetsearchQuery()"
        @keyup.enter="suggestionSelected"
 
      />
      <div
        class="search-input-close"
        @click="showSearchBar = false; resetsearchQuery()"
      >
        <feather-icon icon="XIcon" />
      </div>

      <!-- Suggestions List -->
      <vue-perfect-scrollbar
        :settings="perfectScrollbarSettings"
        class="search-list search-list-main scroll-area overflow-hidden "
        :class="{'show': showSearchBar}"
        tagname="div"
      > 
        <div class="p-1" v-if="filteredData.catalogue && filteredData.catalogue.length">
          <h4>Catalogue</h4>
          <b-row >
            <b-col md="6"  @mousedown.prevent="suggestionSelected('/catalogue/' + vehicule.item.id)"
                   
                    :key="vehicule.item.id"
                   v-for="vehicule in filteredData.catalogue" class="mb-2">


              <vehicule-overview   :vehicule="vehicule.item"  />



            </b-col>

          </b-row>
        </div> 
        <div class="p-1" v-if="filteredData.reprise && filteredData.reprise.length ">
          <h4>Reprise</h4>
        
          <b-row >
            <b-col md="6" @mousedown.prevent="suggestionSelected('/reprise/' + vehicule.item.vehicule.id)" v-for="vehicule in filteredData.reprise" class="mb-2">
              
              <vehicule-overview  :vehicule="vehicule.item.vehicule"  />
            </b-col>

          </b-row>
        </div>
        <div class="p-1" v-if="filteredData.gestion_stock  && filteredData.gestion_stock.length">
          <h4>Gestion de Stock</h4>

          <b-row >
            <b-col md="6"   @mousedown.prevent="suggestionSelected('/gestion-de-stock/' + data.item.id)" v-for="data in filteredData.gestion_stock" class="mb-2">

              <div class="d-flex font-small-3">
                <div class="mr-1">
                  <b-img thumbnail width="180" height="120" :src="data.item.thumbnail + '?w=180&h=120'" />
                </div>
                <div>
                  <div class="font-weight-bold">{{ data.item.marque ? data.item.marque.libelle : '' }} {{
                      data.item.modele ? data.item.modele.libelle : ''
                    }}
                  </div>
                  <div>{{ data.item.version ? data.item.version.libelle : '' }}</div>
                  <div>{{ data.item.immatriculation }} | {{ data.item.vin }}</div>
                  <div>{{ data.item.date_mec }} | {{ data.item.kilometrage }} KM</div>
                  <b-badge style="margin-bottom: 5px" :variant="data.item.publiable.variant">{{data.item.publiable.text}}</b-badge><br/>
                  <b-badge :variant="data.item.stock_date.jours > 90 ? 'danger' : 'success'">{{data.item.stock_date.text}}</b-badge>
                </div>
              </div>
            </b-col>

          </b-row>
        </div>
        <div class="p-1" v-if="filteredData.vendu && filteredData.vendu.length ">
          <h4>Vendu</h4>
          <b-row >
            <b-col md="6" v-for="vehicule in filteredData.vendu" class="mb-2">
              <vehicule-overview  :vehicule="vehicule.item.vehicule"  />
            </b-col>

          </b-row>
        </div>

        <div class="p-1" v-if="filteredData.offres && filteredData.offres.length ">
          <h4>Offres</h4>
 
          <b-row >
            <b-col @mousedown.prevent="suggestionSelected('/list-des-offres/' + vehicule.item.id)"  md="12" v-for="vehicule in filteredData.offres" class="mb-2">
              <b-row>
                <b-col md="6">
                  <div v-if="index <= 1" v-for="(item, index) in vehicule.item.ventes" class="vehicule-item">
                    <vehicule-overview :with-image="false" :vehicule="item.vehicule" />
                  </div>
                  <span v-if="vehicule.item.ventes.length > 2">... + {{vehicule.item.ventes.length - 2}} véhicules </span>
                </b-col>
                <b-col md="6">
                  <div v-if="index <= 1" v-for="(item, index) in vehicule.item.reprises" class="vehicule-item">
                    <vehicule-overview :with-image="false" :vehicule="item.vehicule" />
    
                  </div>
                  <span v-if="vehicule.item.reprises.length > 2">... + {{vehicule.item.reprises.length - 2}} véhicules </span>
                </b-col>
              </b-row>
            
            </b-col>

          </b-row>
        </div>
      </vue-perfect-scrollbar>
    </div>
  </li>
</template>

<script>
import {
  BFormInput, BLink, BImg, BAvatar,
} from 'bootstrap-vue'
import { ref, watch } from '@vue/composition-api'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import useAutoSuggest from '@core/components/app-auto-suggest/useAutoSuggest'
import { title } from '@core/utils/filter'
import router from '@/router'
import store from '@/store'
import VehiculeOverview from "@/components/Vehicule/VehiculeOverview.vue";
 

export default {
  components: {
    VehiculeOverview,
    BFormInput,
    BLink,
    BImg,
    BAvatar,
    VuePerfectScrollbar,
  },
  setup() {
    const showSearchBar = ref(false)

    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
    }

    const suggestionSelected = (link) => {
 
 
       window.location.replace(link)
      resetsearchQuery()
      showSearchBar.value = false
    }

    const {
      searchQuery,
      resetsearchQuery,
      filteredData,
    } = useAutoSuggest()

    watch(searchQuery, val => {
      store.commit('app/TOGGLE_OVERLAY', Boolean(val))
    })

    const currentSelected = ref(-1)
    watch(filteredData, val => {
      if (!Object.values(val).some(obj => obj.length)) {
        currentSelected.value = -1
      } else {
        // Auto Select first item if it's not item-404
        let grpIndex = null

        // eslint-disable-next-line no-restricted-syntax
        for (const [index, grpSuggestions] of Object.values(val).entries()) {
          if (grpSuggestions.length) {
            grpIndex = index
            break
          }
        }

        if (grpIndex !== null) currentSelected.value = `${grpIndex}.0`
      }
    })

    const increaseIndex = (val = true) => {
      /* eslint-disable no-lonely-if, no-plusplus */

      // If there's no matching items
      if (!Object.values(filteredData.value).some(grpItems => grpItems.length)) return

      const [grpIndex, itemIndex] = currentSelected.value.split('.')

      const grpArr = Object.entries(filteredData.value)
      const activeGrpTotalItems = grpArr[grpIndex][1].length

      if (val) {
        // If active item is not of last item in grp
        if (activeGrpTotalItems - 1 > itemIndex) {
          currentSelected.value = `${grpIndex}.${Number(itemIndex) + 1}`

        // If active item grp is not last in grp list
        } else if (grpIndex < grpArr.length - 1) {
          for (let i = Number(grpIndex) + 1; i < grpArr.length; i++) {
            // If navigating group have items => Then move in that group
            if (grpArr[i][1].length > 0) {
              currentSelected.value = `${Number(i)}.0`
              break
            }
          }
        }
      } else {
        // If active item is not of first item in grp
        if (Number(itemIndex)) {
          currentSelected.value = `${grpIndex}.${Number(itemIndex) - 1}`

        // If active item grp  is not first in grp list
        } else if (Number(grpIndex)) {
          for (let i = Number(grpIndex) - 1; i >= 0; i--) {
            // If navigating group have items => Then move in that group
            if (grpArr[i][1].length > 0) {
              currentSelected.value = `${i}.${grpArr[i][1].length - 1}`
              break
            }
          }
        }
      }
      /* eslint-enable no-lonely-if, no-plusplus */
    }

    return {
      showSearchBar,
      perfectScrollbarSettings,
 
      title,
      suggestionSelected,
      currentSelected,
      increaseIndex,

      // AutoSuggest
      searchQuery,
      resetsearchQuery,
      filteredData,
    }
  },
}
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

ul
{
  list-style: none;
  padding: 0;
  margin: 0;
}
p {
  margin: 0;
}

/* .app-auto-suggest {
  position: relative;
}

.auto-suggest-suggestions-list {
  box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1);
  border-radius: 6px;
  position: absolute;
  top: calc(100% + 1rem);
  background-color: white;
  width: 100%;
} */

.suggestion-group-title {
  font-weight: 500;
  padding: .75rem 1rem .25rem;
}

.suggestion-group-suggestion {
  padding: .75rem 1rem
}

.suggestion-current-selected {
  background-color: $body-bg;

  .dark-layout & {
    background-color: $theme-dark-body-bg;
  }
}
</style>
