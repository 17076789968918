<template>
  <div class="navbar-container d-flex content align-items-center">

    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>
     
    <globa-search-bar />
    <b-navbar-nav class="nav align-items-center ml-auto">
      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
      >
        <template #button-content>
          <div class="d-sm-flex d-none user-nav">
            <p class="user-name font-weight-bolder mb-0">
              {{username}}
            </p>
 
            <span class="user-status">{{current_site}} - {{role}}</span>
          </div>
          <b-avatar
            size="40"
            variant="light-primary"
            badge
            :src="require('@/assets/images/avatars/13-small.png')"
            class="badge-minimal"
            badge-variant="success"
          />
        </template>
 

        <b-dropdown-item link-class="d-flex align-items-center" @click="logout">
          <feather-icon
            size="16"
            icon="LogOutIcon"
            class="mr-50"
          />
          <span>Logout</span>
        </b-dropdown-item>
        <b-dropdown-item  v-if="$can('switcher-concession', 'concession')" v-b-modal.modal-select2 link-class="d-flex align-items-center">
          <feather-icon
              size="16"
              icon="Edit3Icon"
              class="mr-50"
          />
          <span>Changer site</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
    <b-modal
        id="modal-select2"
        title="Selectionner un site"
        ok-title="Valider"
        cancel-title="Annuler"
        cancel-variant="outline-secondary"
        @ok="handleSelectSite"
    >
      <b-form>
 
        <b-form-group
            label="Concession"
            label-for="vue-select"
        >
          <select
              id="vue-select"
              v-model="concession"
              label="libelle"
              class="form-control"
          >
            <option value="">Tous les concessions</option>
            <option v-for="item in $store.state.initialConfigModule.initialState.concessions" :value="item">{{item.libelle}}</option>
          </select>
        </b-form-group>
        <b-form-group
            label="Site"
            label-for="vue-select"
        >
          <select
              id="vue-select"
              v-model="site"
              label="libelle"
              class="form-control"
              
          >
            <option value="">Tous les sites</option>
            <option v-for="item in sitesOptions" :value="item">{{item.libelle}}</option>
          </select>
        </b-form-group>
        
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import {
  BLink, BNavbarNav, BNavItemDropdown, BDropdownItem, BDropdownDivider, BAvatar,
} from 'bootstrap-vue'
import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'
import { isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser } from '@/auth/utils'
import useJwt from "@/auth/jwt/useJwt";
import {initialAbility} from "@/libs/acl/config";
import SearchBar from "@core/layouts/components/app-navbar/components/SearchBar.vue";
import GlobaSearchBar from "@/layouts/components/GlobaSearchBar.vue";

export default {
  data(){
    return {
      username : '',
      role : '',
      concession : this.$store.state.initialConfigModule.concession,
      site : this.$store.state.initialConfigModule.site,
    }
  },
  components: {
    GlobaSearchBar,
    SearchBar,
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
  },
  computed : {
    sitesOptions(){
      let _this = this;
      if(!_this.concession) return  [];
      return this.$store.state.initialConfigModule.initialState.sites.filter(function(item){
        return item.concession &&  item.concession.id == _this.concession.id;
      });
    },
    current_site(){

      let userData = getUserData();
      console.log(this.$store.state.initialConfigModule["site"]);
      console.log(this.$store.state.initialConfigModule["concession"]);
      let site = this.$store.state.initialConfigModule["site"] ? 
          'Site : ' + this.$store.state.initialConfigModule["site"].libelle : 
          (this.$store.state.initialConfigModule["concession"] ? 'Concession : ' + this.$store.state.initialConfigModule["concession"].libelle :  'Site : ' +  userData.site.libelle);
      return site;  
    }
  },
  created() {
    let userData = getUserData()
     this.username = userData.fullName;
    
     this.role =   userData.role;
     
  },
  methods : {
    handleSelectSite(bvModalEvt){
 
      this.$store.commit('initialConfigModule/SET_SITE', {
        site : this.site,
        concession : this.concession
      });
      this.$router.go();
    },
    logout() {
      
      
      // Remove userData from localStorage
      // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
      localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
      localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)

      // Remove userData from localStorage
      localStorage.removeItem('userData')

      // Reset ability
      this.$ability.update(initialAbility)

      // Redirect to login page
      this.$router.push({ name: 'auth-login' })
    }
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
}
</script>
