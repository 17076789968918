export default [
    {
        title: 'Dashboard',
        route: 'home',
        icon: 'ActivityIcon',
        resource: 'dashboard',
        action: 'consulter-dashboard',
    },
    {
        title: 'Véhicules',
 
        icon: 'HomeIcon',
        children: [
            {
                title: 'Catalogues',
                route: 'catalogue',
                icon: 'ShoppingCartIcon',
                resource: 'vehicule',
                action: 'consulter-catalogue',
     
            },
            {
                title: 'Reprise',
                route: 'reprise',
                icon: 'RefreshCwIcon',
                resource : 'vehicule',
                action: 'consulter-reprise',
            },
            {
                title: 'Gestion de stock',
                route: 'gestion-de-stock',
                icon: 'HardDriveIcon',
                resource  : 'vehicule',
                action: 'consulter-gestion-stock',
            },
            {
                title: 'Vendu',
                route: 'vendu',
                icon: 'StarIcon',
                resource  : 'vendu',
                action: 'consulter-vendu',
            },
        ],
    },
    {
        title: 'Marchand',
        route: 'marchand',
        icon: 'UserIcon',
        resource  : 'marchand',
        action: 'consulter-marchand',

    },
    {
        title: 'Liste des Offres',
        route: 'list-des-offres',
        icon: 'LayersIcon',
        resource : 'list-bdc',
        action : 'consulter-liste-offres',
    },
    {
        title: 'Clients',
        route: 'clients',
        icon: 'UsersIcon',
        resource  : 'client',
        action: 'consulter-client',
    },
    {
        title: 'Livraisons',
        route: 'livraisons',
        icon: 'TruckIcon',
        resource  : 'livraison',
        action: 'consulter-livraison',
    },
    {
      title : 'Leads',
        route : 'leads',
        icon : 'UserCheckIcon',
        resource : 'leads',
        action: 'export-leads',
    },
    {
        title: 'Paramètres',
        icon: 'SettingsIcon',
        children : [
            {
                title: 'Concessions',
                route: 'concessions',
                icon: 'HomeIcon',
                resource: 'concession',
                action: 'manage-concession',
            },

            {
                title: 'Sites',
                route: 'sites',
                icon: 'HomeIcon',
                resource: 'site',
                action: 'manage-site',
            },
            {
                title: 'Utilisateurs',
                route: 'utilisateurs',
                icon: 'HomeIcon',
                resource: 'utilisateur',
                action: 'manage-utilisateur',
            },
            {
                title: 'Roles',
                route: 'roles',
                icon: 'HomeIcon',
                resource: 'role',
                action: 'gestion-role',
            },


            
        ]
    },
]

 